.clickable-text{
    font-size: 10px;
    font-weight: 600;
    color: var(--primary-text);
    cursor: pointer;
}

    .clickable-text:hover {
        color: var(--primary-text-hover);
    }
