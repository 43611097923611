.study-icon-container {
    height: 24px;
    color: var(--control-icon);
    background-color: var(--surface-background);
    box-shadow: var(--small-shadow);
    border-radius: var(--spacing-2);
    padding-left: var(--spacing-1);
    padding-right: var(--spacing-1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-left: var(--spacing-0);
    margin-right: var(--spacing-0);
}

.study-icon-container:hover{
    cursor: pointer;
    color: var(--control-icon-hover);
    background-color: var(--active-background);
}

.study-icon-container span {
    font-size: 12px;
    margin-left: var(--spacing-1);
}

.study-icon-hidden {
    opacity: 0;
}