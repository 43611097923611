.list-pagination {
    width: 100%;
    display: flex;
    padding: var(--spacing-2);
    justify-content: center;
    align-items: center;
    color: var(--primary-text);
    font-size: 12px;
}

.list-pagination-item {
    width: 32px;
    height: 32px;
    border: 1px solid var(--control-stroke);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: var(--spacing-2);
    margin-right: var(--spacing-2);
    border-radius: var(--spacing-0);
}

    .list-pagination-item:hover {
        cursor: pointer;
        background: var(--active-background);
        color: var(--secondary-text);
    }

    .list-pagination-item.active {
        cursor: pointer;
        background: var(--active-secondary-background);
        color: var(--secondary-text);
        font-weight: 600;
    }

    .list-pagination-item.hidden {
        display: none;
    }