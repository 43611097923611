.dashboard-container{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
}

.dashboard-item{
    min-width: min(610px, calc(100% - 30px));
    margin: 0 15px;
}