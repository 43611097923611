.attachment-table-title {
    font-size: 14px;
    font-weight: 600;
    color: var(--primary-text);
    margin-top: var(--spacing-1);
    margin-bottom: var(--spacing-1);
}

.attachment-table{
    font-size: 14px;
    font-weight: 600;
}

.attachment-table td{
    text-align: left;
}

.attachment-table .item-icon{
    font-size: 24px;
    margin-right: var(--spacing-1);
}

.attachment-table-action-icon {
    font-size: 24px;
    margin-left: var(--spacing-1);
    margin-right: var(--spacing-1);
}

.attachment-table-action-icon:hover {
    cursor: pointer;
    opacity: 0.6;
}

.attachment-table-action-icon.small {
    font-size: 16px;
}

.attachment-name{
    word-wrap: anywhere;
    width: 100%;
}

.attachment-item .attachment-tooltip{
    display: none;
    padding: 2px 3px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.attachment-item:hover .attachment-tooltip {
    display: block;
    position: absolute;
    font-size: 10px;
    margin-top: -48px;
    background: var(--surface-background);
    color: var(--primary-text);
    border: 1px solid var(--control-stroke);
    border-radius: 4px;
}
