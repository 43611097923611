.double-button {
    width: 100%;
    height: 48px;
    border-radius: var(--spacing-3);
    box-shadow: var(--default-shadow);
    background-color: var(--control-background);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--primary-text);
    font-size: 12px;
    font-weight: 600;
}

.double-button-side {
    width: 50%;
    height: 100%;
    border-radius: var(--spacing-3);
    padding-left: var(--spacing-3);
    padding-right: var(--spacing-3);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer
}

    .double-button-side:hover {
        color: var(--primary-text-hover);
    }

    .double-button-side.active {
        background-color: var(--active-secondary-background);
        color: var(--secondary-text);
    }

    .double-button-side:hover.active {
        color: var(--secondary-text-hover);
    }
