.document-item{
    font-size: 18px;
    font-weight: 400;
}

a.document-item ,
a:link.document-item ,
a:visited.document-item  {
    color: var(--royalBlue);
}

a:focus.document-item ,
a:hover.document-item ,
a:active.document-item  {
    color: var(--dodgerBlue);
}

.document-item svg{
    font-size: 1.2em;
    margin-bottom: 2px;
}