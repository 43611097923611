.documents-container{
    min-width: 420px;
    max-width: 640px;
    color: var(--primary-text);
}

.documents-container-title{
    font-size: 20px;
    font-weight: 400;
}

.link-contact-support{
    font-size: 16px;
    font-weight: bold;
}

.link-contact-support a,
.link-contact-support a:link,
.link-contact-support a:visited {
    color: var(--primary-text);
}

.link-contact-support a:focus,
.link-contact-support a:hover,
.link-contact-support a:active {
    color: var(--primary-text-hover);
}

.link-contact-support svg {
    font-size: 1.4em;
    margin-bottom: 2px;
}

.divider{
    border-top: 2px solid var(--control-stroke);
}