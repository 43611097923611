.input-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--control-background);
    height: 48px;
    min-height: 48px;
    border-radius: var(--spacing-3);
    padding-left: var(--spacing-3);
    padding-right: var(--spacing-3);
    border: solid 1px var(--control-stroke);
    box-sizing: border-box;
}

.input-box ::placeholder {
    opacity: 65%;
    font-style: italic;
    font-size: 0.9em;
}

.input-box:focus-within {
    border: solid 2px var(--control-stroke-active);
}

.input-box.error {
    border: solid 2px var(--control-stroke-error);
}

.input-box .input-box-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: var(--spacing-1);
    color: var(--primary-text);
}

.input-box .input-box-container span {
    font-size: 9px;
    margin-bottom: var(--spacing-0);
    opacity: 0.5;
    cursor: default;
}

.input-box.error .input-box-container span {
    color: var(--error-text);
    cursor: default;
}

.input-box textarea {
    resize: none !important;
}

.input-box .input-box-container input, input:focus, input:active, textarea, textarea:focus, textarea:active {
    font-size: 13px;
    border: none;
    outline: none;
    background: transparent;
}

.input-box .input-box-icon {
    color: var(--control-icon);
    font-size: 13px;
    margin-right: -8px;
    opacity: 0.5;
}

.input-box-icon-toggle:hover {
    cursor: pointer;
    opacity: 0.8;
}

.input-box-textarea {
    height: auto !important;
}