.not-found-container {
    position: relative;
    top: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    padding: var(--spacing-4);
    justify-content: center;
    align-items: center;
    min-width: var(--min-width);
    min-height: var(--min-height);
}

.not-found {
    color: var(--primary-text);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
}

    .not-found svg {
        width: 150px;
    }

.not-found-title {
    font-size: 26px;
    font-weight: 600;
}

.not-found-text {
    font-size: 18px;
}

.not-found-link {
    font-size: 12px;
    font-weight: 600;
    color: var(--primary-text);
}

    .not-found-link:hover {
        color: var(--primary-text-hover);
        cursor: pointer;
    }
