.account-settings-container{
    margin-bottom: calc(-1 * var(--spacing-3));
}

select.account-settings-dropdown {
    background: transparent;
    border: none;
    color: var(--primary-text);
    font-size: 12px;
    margin-left: -4px;
}

select.account-settings-dropdown:disabled {
    opacity: 1 !important;
    -o-appearance: none;
    -ms-appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

select.account-settings-dropdown:hover {
    color: var(--primary-text-hover) !important;
}

select.account-settings-dropdown:focus {
    border: none !important;
    outline: none !important;
}

select.account-settings-dropdown option {
    background: var(--window-background);
    color: var(--primary-text-hover);
}

select.account-settings-dropdown option:hover {
    background-color: var(--active-background);
    color: var(--primary-text);
}