.stethoscope-playback-control-button{
    width: 24px;
    height: 24px;
    border-radius: var(--spacing-2);
    box-shadow: var(--small-shadow);
    background-color: var(--control-background);
    color: var(--control-icon);
    font-size: 10px;
    opacity: 0.5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stethoscope-playback-control-button:hover{
    color: var(--control-icon-hover);
    opacity: 1;
}