.user-avatar-img{
    width: 24px !important;
    height: 24px !important;
}

.type-icon span {
    display: none;
    padding: 2px 3px;
    margin-bottom: 64px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.type-icon:hover span {
    display: inline;
    position: absolute;
    font-size: 10px;
    background: var(--surface-background);
    color: var(--primary-text);
    border: 1px solid var(--control-stroke);
    border-radius: 4px;
}