.container-label{
    width: 420px;
    padding: 2px 8px 2px 8px;
    border-radius: 16px;
    border: solid 2px black;
    display: flex;
    flex-direction: column;
    align-items: stretch;
}

.container-label-first-row{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.container-label-second-row{
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.container-label-third-row{
    height: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.container-label-divider{
    background-color: black;
    height: 2px;
}

.device-label-icon{
    font-size: 40px;
}

.device-label-spec{
    font-size: 14px;
    font-weight: 600;
    margin: 0 8px 0 8px;
    word-break: keep-all;
    white-space: nowrap;
}

.device-label-spec-icon{
    font-size: 20px;
}

.device-label-data-title{
    font-size: 18px;
    font-weight: 600;
    width: 100%;
    text-align: center;
    margin: 2px 0 2px 0;
    padding: 0 4px 0 4px;
    border: solid 1px black;
}

.device-label-data-value{
    font-size: 13px;
    font-weight: 600;
    width: 100%;
    text-align: left;
    margin: 2px 0 2px 0;
    padding: 0 4px 0 4px;
}

.device-label-sn{
    font-size: 16px;
}

.device-label-certificate-number{
    font-size: 8px;
    font-weight: 600;
}

.device-label-address{
    white-space: pre-line;
    font-size: 10px;
    font-weight: 800;
    margin: 0 8px 0 8px;
}


.device-label-year{
    position: absolute;
    font-size: 10px;
    font-weight: 600;
    top: 24px;
    left: 0;
    width: 100%;
    text-align: center;
}
