@keyframes logo_rotation {
    0% {
        transform: rotateY(0deg);
    }

    50% {
        transform: rotateY(180deg);
    }

    100% {
        transform: rotateY(0deg);
    }
}

.async-indicator-container {
    position: relative;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: var(--spacing-4);
}

.async_indicator-logo {
    width: 48px;
    animation: logo_rotation 5s linear infinite;
}
