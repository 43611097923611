.small-double-button {
    width: 180px;
    min-width: 180px;
    height: 32px;
    border-radius: var(--spacing-2);
    background-color: var(--control-background);
    border: solid 1px var(--control-stroke);
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--primary-text);
    font-size: 12px;
    font-weight: 600;
}

.small-double-button-side {
    width: 50%;
    height: 100%;
    border-radius: var(--spacing-3);
    padding-left: var(--spacing-3);
    padding-right: var(--spacing-3);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer
}

    .small-double-button-side:hover {
        color: var(--primary-text-hover);
    }

    .small-double-button-side.active {
        background-color: var(--active-secondary-background);
        color: var(--secondary-text);
    }

    .small-double-button-side:hover.active {
        color: var(--secondary-text-hover);
    }
