.spiro-viewer {
    width: calc(var(--min-width) - 2 * var(--spacing-2));
    min-width: calc(var(--min-width) - 2 * var(--spacing-2));
    align-self: center;
}

.spiro-viewer-button {
    width: 32px;
    height: 32px;
    font-size: 16px;
    border-radius: var(--spacing-2);
    color: var(--control-icon);
    background-color: var(--control-background);
    box-shadow: var(--small-shadow);
    margin-left: var(--spacing-0);
    margin-right: var(--spacing-0);
    display: flex;
    justify-content: center;
    align-items: center;
}

.spiro-viewer-button.large-icon {
    font-size: 24px;
}

.spiro-viewer-button:hover {
    cursor: pointer;
    color: var(--control-icon-hover);
}

.spiro-viewer-button.active {
    background-color: var(--active-background);
    color: var(--secondary-text);
}

.spiro-viewer-button.active:hover {
    cursor: pointer;
}

.spiro-viewer-button.disabled {
    opacity: 0.4;
}

.spiro-viewer-button.disabled:hover {
    cursor: default;
}

.spiro-viewer-button span {
    display: none;
    padding: 2px 3px;
    margin-bottom: 64px;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.spiro-viewer-button:hover span {
    display: inline;
    position: absolute;
    font-size: 10px;
    background: var(--surface-background);
    color: var(--primary-text);
    border: 1px solid var(--control-stroke);
    border-radius: 4px;
}

.result-table{
    min-width: calc(var(--min-width) - 2 * var(--spacing-2));
    max-width: 720px;
    background-color: var(--surface-background);
    border-radius: var(--spacing-0);
    padding: var(--spacing-2);
    box-shadow: var(--default-shadow);
    color: var(--primary-text);
    font-size: 12px;
}

.result-table table {
    width: 100%;
}

.result-table tr {
    border-bottom: solid 1px var(--control-background);
}

.result-table tr:last-child {
    border-bottom: none;
}

.result-table th,td {
    text-align: center;
}

.result-table th {
    width: 160px;
    font-weight: 500;
    padding-top: var(--spacing-0);
    padding-bottom: var(--spacing-0);
}

.result-table td {
    padding-top: var(--spacing-0);
    padding-bottom: var(--spacing-0);
}

.result-table th:first-child{
    text-align: left;
}

.interpretation-title{
    color: var(--primary-text);
    font-size: 12px;
    font-weight: 600;
    padding-top: var(--spacing-2);
}

.interpretation-text{
    color: var(--primary-text);
    font-size: 12px;
    font-weight: 400;
    padding-top: var(--spacing-2);
    padding-bottom: var(--spacing-2);
    white-space: pre;
}