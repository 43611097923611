.comments-title{
    font-size: 14px;
    color: var(--primary-text);
    font-weight: bold;
}

.input-box-comment{
    background-color: var(--surface-background) !important;
}

.reference-comment-remove-icon{
    font-size: 16px !important;
    color: var(--primary-text);
}

.reference-comment-remove-icon:hover{
    cursor: pointer;
    color: var(--primary-text-hover);
}