.input-wrapper-container {    
    position: relative;
    padding-right: 6px;
}

    .input-wrapper-container svg {
        min-width: 1em;
    }

.input-wrapper-text {
    overflow-wrap: break-word;
    word-break: break-all;
}

.validation-error {
    --validation-error-window-height: 40px;
    position: absolute;
    top: calc((100% - var(--validation-error-window-height)) / 2);
    left: calc(100% + 48px);
    height: var(--validation-error-window-height);
    border-radius: var(--spacing-3);
    background-color: var(--control-background);
    box-shadow: var(--default-shadow);
    color: var(--error-text);
    font-size: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: var(--spacing-2);
    padding-right: var(--spacing-3);
}

.validation-error-icon {
    margin-right: var(--spacing-1);
}

.hidden{
    opacity: 0;
}
