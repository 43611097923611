.search-input-container {
    width: 30%;
    min-width: 240px;
    height: 32px;
    padding-left: var(--spacing-2);
    padding-right: var(--spacing-1);
    border-radius: var(--spacing-2);
    display: flex;
    justify-content: space-between;
    background-color: var(--control-background);
    align-items: center;
    border: solid 1px var(--control-stroke);
    margin-top: var(--spacing-2);
    margin-bottom: var(--spacing-2);
    box-sizing: border-box;
}

.search-input-container:focus-within {
    border: solid 2px var(--control-stroke-active);
}

.search-input-container input{
    width: 100%;
}

.search-input-container input, input:focus, input:active {
    font-size: 12px;
    border: none;
    outline: none;
    background: transparent;
    color: var(--primary-text);
}

.search-input-container input:focus::-webkit-input-placeholder {
    color: transparent;
}

.search-input-container input::-webkit-input-placeholder {
    opacity: 0.7;
}

.search-input-container .search-input-icon {
    color: var(--control-icon);
    font-size: 12px;
    opacity: 0.5;
}

.search-input-container .search-input-icon-clear:hover {
    opacity: 0.8;
    cursor: pointer;
}
