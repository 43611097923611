.breadcrumbs {
    font-size: 12px;
    display: flex;
    color: var(--primary-text);
    margin-right: var(--spacing-2);
    margin-left: var(--spacing-2);
    margin-bottom: var(--spacing-2);
    justify-content: center;
}

.breadcrumbs-link {
    color: var(--primary-text-hover);
}

    .breadcrumbs-link:hover {
        color: var(--primary-text);
        cursor: pointer;
    }

    .breadcrumbs-link.breadcrumbs-active {
        color: var(--primary-text);
    }

    .breadcrumbs-link svg {
        font-size: 14px;
        margin-right: var(--spacing-0);
    }

.breadcrumbs-divider {
    margin-left: var(--spacing-0);
    margin-right: var(--spacing-0);
}