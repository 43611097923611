.toast-container {
    padding: var(--spacing-2);
    border-radius: var(--spacing-3);
    background-color: var(--surface-background);
    color: var(--primary-text);
    box-shadow: var(--small-shadow);
    opacity: 0;
    font-size: 12px;
    position: fixed;
    margin: var(--spacing-3);
    bottom: var(--spacing-4);
    right: var(--spacing-4);
    transition: opacity linear 0.5s;
}

.toast-error {
    color: var(--error-text);
}

.toast-visible {
    opacity: 0.6;
}
