.check-box-check{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20px;
    height: 20px;
    border-radius: var(--spacing-1);
    padding: 3px;
    background-color: var(--control-background);
    border: solid 1px var(--control-stroke);
    box-sizing: border-box;
    color: var(--control-icon);
}

.check-box-check:hover{
    cursor: pointer;
}

.check-box-label{
    font-size: 12px;
    opacity: 0.8;
}