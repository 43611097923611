.study-icon-pb-container {
    height: 24px;
    background-color: var(--active-secondary-background);
    color: var(--secondary-text);
    box-shadow: var(--small-shadow);
    border-radius: var(--spacing-2);
    padding-left: var(--spacing-1);
    padding-right: var(--spacing-1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    margin-left: var(--spacing-0);
    margin-right: var(--spacing-0);
}

.study-icon-pb-container:hover{
    color: var(--secondary-text-hover);
}

.study-icon-pb-container.active{
    color: var(--control-icon);
    background-color: var(--control-background);
}

.study-icon-pb-container.active:hover{
    cursor: pointer;
    color: var(--primary-text);
}

.study-icon-pb-container span {
    font-size: 12px;
    margin-left: var(--spacing-1);
}
