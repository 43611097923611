.profile-title {
    font-size: 12px;
    font-weight: 600;
    text-align: center;
    padding-bottom: var(--spacing-2);
    border-bottom: solid 1px var(--control-stroke);
}

.profile-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: var(--spacing-2);
    margin-bottom: var(--spacing-2);
    font-size: 11px;
    color: var(--primary-text);
}

.profile-avatar {
    width: 64px !important;
    height: 64px !important;
}

.profile-buttons {
    width: 200px;
    height: 48px;
    display: flex;
    align-items: center;
}

.profile-button {
    height: 100%;
    width: 50%;
    padding-left: var(--spacing-2);
    padding-right: var(--spacing-2);
    border: solid 1px var(--control-stroke);
    display: flex;
    align-items: center;
    text-align: center;
}

    .profile-button.left {
        border-top-left-radius: var(--spacing-3);
        border-bottom-left-radius: var(--spacing-3);
    }

    .profile-button.right {
        border-top-right-radius: var(--spacing-3);
        border-bottom-right-radius: var(--spacing-3);
    }

    .profile-button:hover {
        color: var(--primary-text-hover);
        cursor: pointer;
    }

.profile-save-button-container {
    display: flex;
    justify-content: center;
}

.profile-save-button {
    width: 50%;
}

.profile-error {
    color: var(--error-text);
    font-size: 11px;
    text-align: center;
    white-space: pre-line;
}
