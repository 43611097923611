.infocard-body {
    width: 100%;
    height: 128px;
    border-radius: var(--spacing-2);
    color: var(--secondary-text);
    padding: 0;
    position: relative;
    overflow: hidden;
    box-shadow: var(--default-shadow);
}

    .infocard-body.patients {
        background-color: var(--dodgerBlue);
    }

    .infocard-body.records {
        background-color: var(--viking);
    }

    .infocard-body.users {
        background-color: var(--summerSky);
    }

    .infocard-body svg {
        position: absolute;
        width: 100%;
        height: 120%;
        left: 0;
        top: -10%;
    }

.infocard-container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: var(--spacing-2);
    padding-right: var(--spacing-2);
}

.infocard-title {
    font-size: 20pt;
    font-weight: 400;
}

.infocard-caption {
    font-size: 11pt;
    font-weight: 400;
}

.infocard-button {
    height: 32px;
    display: flex;
    border-radius: var(--spacing-3);
    padding-left: var(--spacing-2);
    padding-right: var(--spacing-2);
    align-items: center;
    justify-content: center;
    background-color: var(--overlay-25);
    font-size: 10pt;
    margin-left: var(--spacing-2);
}

    .infocard-button:hover {
        cursor: pointer;
        background-color: var(--overlay-50);
        color: var(--secondary-text);
    }

