.google-sign-in-button {
    width: 100%;
    height: 48px;
    border-radius: var(--spacing-3);
    box-shadow: var(--default-shadow);
    background-color: var(--control-background);
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--primary-text);
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    padding-left: var(--spacing-3);
    padding-right: var(--spacing-3);
}

    .google-sign-in-button:hover {
        background-color: var(--active-background);
        color: var(--secondary-text);
    }

